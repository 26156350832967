const ImagePreview = ({ image, isModalOpen, closeModal }) => {
  return (
    <>
      {isModalOpen && (
        <div className="z-50 fixed inset-0 h-full flex bg-gray-900 bg-opacity-70 w-full items-center justify-center">
          <div className=" p-4 rounded-lg shadow-lg relative  w-full h-full">
            <button
              className="absolute top-2 text-[20px] font-bold right-2 text-white bg-black rounded-full px-2 hover:text-gray-800"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={image}
              alt="Full Size Preview"
              className="w-full h-full object-cover"
              onClick={closeModal}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default ImagePreview;
