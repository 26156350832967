import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HowItWorksPage = () => {

  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="font-bold text-[#005555]">Share Bric</h2>
        <div className=" mt-4">
          <p className="text-[#005555] font-bold text-lg">
            {" "}
            Mission Statement:
          </p>
          <span>
            Our mission is to democratize real estate investment by providing
            accessible, secure, and transparent fractional ownership
            opportunities. We aim to revolutionize the property market with
            innovative solutions that empower individuals to build wealth and
            achieve financial freedom through real estate, while ensuring
            rigorous vetting and professional management for optimal returns and
            peace of mind.
          </span>
        </div>
        <div className="mt-4">
          <p className="text-[#005555] font-bold text-lg">
            What you can expect from US:
          </p>
          <span>
            Sharebric offers a seamless platform where you can invest in
            high-quality real estate properties through fractional ownership
            with minimal capital. We provide detailed property listings,
            comprehensive vetting reports, and real-time updates to ensure
            transparency and informed decision-making. Our professional
            management services handle all aspects of property maintenance and
            rental operations, ensuring regular income and potential capital
            appreciation. Join us to access exclusive investment opportunities
            and effortlessly build a diversified real estate portfolio.
          </span>
        </div>
      </div>
      <div className="container mt-6  px-4 sm:px-6 lg:px-8">
        <div className=" flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="font-bold text-[22px] ">
              {" "}
              Traditional Real Estate in India
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Whole Ownership Requirement:</h3>
                <p className="text-black-50 text-xs">
                  Buyers must purchase entire properties, necessitating
                  significant upfront capital.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading">Complex Transaction Process:</h3>
                <p className="text-black-50  text-xs">
                  Involves multiple steps such as property search, legal
                  paperwork, negotiations, and registration, which can be
                  time-consuming.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> High Financial Barrier: </h3>
                <p className="text-black-50  text-xs">
                  High capital requirements make real estate investment
                  accessible mainly to wealthy individuals or institutions.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Limited Liquidity: </h3>
                <p className="text-black-50  text-xs">
                  Selling whole properties can be slow and may lack flexibility
                  in market conditions.
                </p>
              </div>
            </div>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Sole Responsibility: </h3>
                <p className="text-black-50  text-xs">
                  Individual owners bear full responsibility for property
                  maintenance, taxes, and managing market risks.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div
              className="flex flex-col justify-center w-full"
            >
              <span className=" text-[#005555] mt-4  mb-4 text-center w-full animate-bounce">
                Whole Ownership Requirement{" "}
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className=" text-[#005555] mt-4  mb-4 text-center w-full animate-bounce">
                Complex Transaction Process
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className=" text-[#005555] mt-4  mb-4 text-center w-full animate-bounce">
                High Financial Barrier
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className=" text-[#005555] mt-4  mb-4 text-center w-full animate-bounce">
                Limited Liquidity
              </span>
              <FontAwesomeIcon icon={faArrowDown} />{" "}
              <span className=" text-[#005555] mt-4  text-center w-full animate-bounce">
                Sole Responsibility
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold">
              {" "}
              Sharebric's Fractional Ownership Approach
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Fractional Ownership: </h3>
                <p className="text-black-50 text-xs">
                  Allows investors to purchase shares of properties, reducing
                  the required capital and making investment accessible to a
                  broader range of individuals.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Simplified Transactions:</h3>
                <p className="text-black-50  text-xs">
                  Streamlines the buying process through an efficient online
                  platform, typically completing transactions in fewer steps.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Enhanced Accessibility: </h3>
                <p className="text-black-50  text-xs">
                  Democratizes real estate investment by lowering the financial
                  barrier to entry, welcoming investors with varying budgets.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Improved Liquidity: </h3>
                <p className="text-black-50  text-xs">
                  Facilitates easier buying and selling of property shares,
                  providing investors with greater flexibility and liquidity
                  compared to whole property ownership.
                </p>
              </div>
            </div>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Shared Responsibility: </h3>
                <p className="text-black-50  text-xs">
                  Centralized management handles property maintenance and
                  administrative tasks, relieving individual investors of these
                  responsibilities.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div  className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce  ">
                Fractional Ownership
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Simplified Transactions
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Enhanced Accessibility
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Improved Liquidity
              </span>
              <FontAwesomeIcon icon={faArrowDown} />{" "}
              <span className="text-[#005555] mt-4  text-center w-full animate-bounce ">
                Shared Responsibility
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold">
              {" "}
              Property Search and Identification
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Research :</h3>
                <p className="text-black-50 text-xs">
                  Begin by researching potential areas and properties based on
                  budget, purpose (residential, commercial, rental income), and
                  personal preferences.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Consultation :</h3>
                <p className="text-black-50  text-xs">
                  Consult with real estate agents, brokers, or property
                  consultants for options and advice.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Site Visits :</h3>
                <p className="text-black-50  text-xs">
                  Schedule and conduct site visits to shortlisted properties to
                  inspect the location, condition, and amenities.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Research
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Consultation
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Site Visits
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold">
              {" "}
              Budget and Financial Planning
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Budget Assessment : </h3>
                <p className="text-black-50 text-xs">
                  Determine the total budget, including the purchase price,
                  taxes, registration fees, and other related costs.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Financing Options :</h3>
                <p className="text-black-50  text-xs">
                  Explore financing options such as home loans, mortgages, or
                  personal savings.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Pre-Approval : </h3>
                <p className="text-black-50  text-xs">
                  Get pre-approval for a home loan if required to understand
                  borrowing capacity and interest rates.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Budget Assessment
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Financing Options
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Pre-Approval
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold"> Legal Due Diligence</h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Title Verification : </h3>
                <p className="text-black-50 text-xs">
                  Verify the property's legal title to ensure it is free from
                  encumbrances and has a clear ownership history.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Document Check :</h3>
                <p className="text-black-50  text-xs">
                  Check all relevant documents, including sale deed, land
                  records, encumbrance certificate, property tax receipts, and
                  approvals from local authorities.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Legal Assistance : </h3>
                <p className="text-black-50  text-xs">
                  Hire a legal expert or property lawyer to conduct thorough due
                  diligence and verify the authenticity of documents.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Title Verification
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Document Check
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Legal Assistance
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold">
              {" "}
              Negotiation and Agreement
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Price Negotiation : </h3>
                <p className="text-black-50 text-xs">
                  Negotiate the price with the seller or developer to arrive at
                  a mutually agreed amount.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Agreement Drafting :</h3>
                <p className="text-black-50  text-xs">
                  Draft a sale agreement outlining the terms and conditions,
                  including the agreed price, payment schedule, possession date,
                  and other important clauses.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Token Advance : </h3>
                <p className="text-black-50  text-xs">
                  Pay a token advance to the seller as a sign of commitment to
                  the purchase.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Price Negotiation
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Agreement Drafting
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Token Advance
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold"> Payment and Financing</h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Down Payment : </h3>
                <p className="text-black-50 text-xs">
                  Make the down payment as per the agreed terms in the sale
                  agreement
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Loan Disbursement :</h3>
                <p className="text-black-50  text-xs">
                  If financing through a home loan, complete the loan
                  application process, and ensure the loan amount is disbursed
                  to the seller.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Balance Payment : </h3>
                <p className="text-black-50  text-xs">
                  Pay the remaining balance amount to the seller as per the
                  agreed schedule.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Down Payment
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Loan Disbursement
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Balance Payment
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold">
              {" "}
              Registration and Stamp Duty
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Stamp Duty : </h3>
                <p className="text-black-50 text-xs">
                  Pay the applicable stamp duty based on the property value to
                  the state government.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Registration :</h3>
                <p className="text-black-50  text-xs">
                  Register the property at the local sub-registrar’s office.
                  Both the buyer and seller must be present, along with
                  witnesses.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Document Submission : </h3>
                <p className="text-black-50  text-xs">
                  Submit all required documents, including the sale deed, ID
                  proofs, photographs, and payment receipts, for registration.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Registration Fee : </h3>
                <p className="text-black-50  text-xs">
                  Pay the registration fee as applicable.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Stamp Duty Payment
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Registration
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Document Submission
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Registration Fee
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold"> Possession and Handover</h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Possession Certificate : </h3>
                <p className="text-black-50 text-xs">
                  Obtain the possession certificate from the seller or
                  developer.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Handover :</h3>
                <p className="text-black-50  text-xs">
                  Take physical possession of the property and ensure all keys,
                  documents, and relevant papers are handed over.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Mutation of Property : </h3>
                <p className="text-black-50  text-xs">
                  Apply for mutation of the property in local municipal records
                  to reflect the change in ownership
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Possession Certificate
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Handover
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Mutation of Property
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-between mb-5">
          <div className="mr-6">
            <h3 className="text-[22px] font-bold">
              {" "}
              Post-Purchase Formalities
            </h3>
            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Utility Transfers : </h3>
                <p className="text-black-50 text-xs">
                  Transfer utility connections (electricity, water, gas) to the
                  new owner’s name.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Property Tax Registration :</h3>
                <p className="text-black-50  text-xs">
                  Update property tax records with the local municipal body to
                  reflect the new ownership.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <div className="feature-text">
                <h3 className="heading"> Maintenance and Upkeep : </h3>
                <p className="text-black-50  text-xs">
                  Arrange for regular maintenance and upkeep of the property to
                  ensure it remains in good condition.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex flex-col justify-center w-full">
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Utility Transfers
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Property Tax Registration
              </span>
              <FontAwesomeIcon icon={faArrowDown} />
              <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                Maintenance and Upkeep
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="font-bold text-[#005555]"> Bric Share Investment</h2>
        <div className="container mt-6  px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between mb-5">
            <div className="mr-6">
              <h3 className="text-[22px] font-bold">
                {" "}
                Bric Share Registration
              </h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Sign Up : </h3>
                  <p className="text-black-50 text-xs">
                    Investors begin by signing up on the Sharebric platform,
                    providing necessary personal details to create an account.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Profile Verification :</h3>
                  <p className="text-black-50  text-xs">
                    The platform verifies the investor's profile through
                    identity checks and validation of provided information to
                    ensure authenticity and compliance.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col justify-center w-full">
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Sign Up
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Profile Verification
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-5">
            <div className="mr-6">
              <h3 className="text-[22px] font-bold"> Property Selection</h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Browse Listings : </h3>
                  <p className="text-black-50 text-xs">
                    Investors can browse through a curated list of properties
                    available for fractional ownership on the platform.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Select Property :</h3>
                  <p className="text-black-50  text-xs">
                    Investors choose the property they wish to invest in from
                    their shortlisted options.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col justify-center w-full">
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Property Selection
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Browse Listings and select
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-5">
            <div className="mr-6">
              <h3 className="text-[22px] font-bold"> Payment</h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Transfer Funds : </h3>
                  <p className="text-black-50 text-xs">
                    Investors transfer the decided investment amount to the
                    platform, following the specified payment methods and
                    protocols.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Receive Ownership Shares: :</h3>
                  <p className="text-black-50  text-xs">
                    Upon successful payment, investors receive digital
                    certificates or proof of ownership shares in the property,
                    representing their fractional stake.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col justify-center w-full">
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Transfer Funds
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Receive Ownership Shares
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-5">
            <div className="mr-6">
              <h3 className="text-[22px] font-bold">
                {" "}
                Ownership and Management
              </h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Professional Management : </h3>
                  <p className="text-black-50 text-xs">
                    The platform provides professional management services for
                    the property, handling all aspects of maintenance, tenant
                    management, and administrative tasks.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading">Receive Updates :</h3>
                  <p className="text-black-50  text-xs">
                    Investors receive regular updates and reports on the
                    property’s performance, rental income, and any maintenance
                    activities conducted.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col justify-center w-full">
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Professional Management
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Receive Updates
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-5">
            <div className="mr-6">
              <h3 className="text-[22px] font-bold"> Returns and Dividends</h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Receive Rental Income : </h3>
                  <p className="text-black-50 text-xs">
                    Investors receive their share of rental income generated
                    from the property, distributed periodically based on their
                    ownership stake.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Property Appreciation : </h3>
                  <p className="text-black-50 text-xs">
                    Investors benefit from any appreciation in the property’s
                    value over time, contributing to potential capital gains.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Periodic Distributions :</h3>
                  <p className="text-black-50  text-xs">
                    The platform distributes earnings from rental income and
                    other profits periodically to the investors’ accounts.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col justify-center w-full">
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Receive Rental Income
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Property Appreciation
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Periodic Distributions
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-5">
            <div className="mr-6">
              <h3 className="text-[22px] font-bold"> Liquidity and Exit</h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Sell Shares on Platform : </h3>
                  <p className="text-black-50 text-xs">
                    If investors wish to exit their investment, they can list
                    their shares for sale on the platform’s marketplace, making
                    it available for other investors to purchase
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Exit Investment :</h3>
                  <p className="text-black-50  text-xs">
                    Upon finding a buyer, investors can complete the sale of
                    their shares, effectively exiting the investment.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading"> Receive Proceeds :</h3>
                  <p className="text-black-50  text-xs">
                    Investors receive the proceeds from the sale of their
                    shares, reflecting their share’s market value at the time of
                    sale.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col justify-center w-full">
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Sell Shares on Platform
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Exit Investment
                </span>
                <FontAwesomeIcon icon={faArrowDown} />
                <span className="text-[#005555] mt-4  mb-4 text-center w-full animate-bounce ">
                  Receive Proceeds
                </span>
              </div>
            </div>
          </div>
        </div>

        <h2 className="font-bold text-[#005555] mt-8 "> What are my returns</h2>
        <span className="mt-2 px-4 sm:px-6 lg:px-8">
          {" "}
          You can see your return on investment (ROI) easily on our platform:
        </span>
        <div className="mt-2 px-4 sm:px-6 lg:px-8">
          <div className="flex">
            <h3 className="text-[18px]">1. Dashboard : </h3>
            <p>
              {" "}
              Check your personalized dashboard for a clear overview of your
              investments and current performance.
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[18px]">2. Income Updates : </h3>
            <p>
              Receive regular updates on rental income and any changes in
              property value.
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[18px]">3. Reports : </h3>
            <p>
              Access reports that show your investment growth and overall ROI.{" "}
            </p>
          </div>

          <p className="mt-4 font-bold text-primary">
            {" "}
            Our Property Selection Criteria - Is it the right choice?
          </p>
          <p className="text-[12px]">
            At Sharebric, we meticulously select properties for investment to
            provide our users with the best possible opportunities. Our property
            selection process involves:
          </p>

          <div className="flex mt-2">
            <h3 className="text-[15px]  whitespace-nowrap">
              1. Location Analysis :{" "}
            </h3>
            <p className="text-[12px]">
              We prioritize properties in desirable locations with strong
              economic fundamentals, high rental demand, and potential for
              future growth.{" "}
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[15px]  whitespace-nowrap">
              2. Quality Assessment :{" "}
            </h3>
            <p className="text-[12px]">
              Each property undergoes a detailed assessment of its construction
              quality, amenities, and overall condition to ensure it meets our
              standards for investment.
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[15px]  whitespace-nowrap">
              3. Market Research :{" "}
            </h3>
            <p className="text-[12px]">
              Our team conducts extensive market research to evaluate the
              property's potential in terms of rental yield, capital
              appreciation, and market trends.
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[15px]  whitespace-nowrap">
              4. Financial Due Diligence :{" "}
            </h3>
            <p className="text-[12px]">
              We analyze the financial viability of each property, considering
              factors such as rental income projections, operating expenses, and
              expected return on investment (ROI).
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[15px] whitespace-nowrap">
              5. Legal and Compliance Review :{" "}
            </h3>
            <p className="text-[12px]">
              All properties undergo a rigorous legal review to verify
              ownership, title deeds, regulatory compliance, and any potential
              legal issues.
            </p>
          </div>

          <div className="flex mt-2">
            <h3 className="text-[15px]  whitespace-nowrap">
              6. Risk Management :{" "}
            </h3>
            <p className="text-[12px]">
              We assess risk factors associated with each property and mitigate
              them to ensure investor confidence and security
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksPage;
