import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { signupApi } from "../../api";
import toast from "toastr";
import Step1 from "../../components/RegisterSteps/step1";
import Loader from "../../components/Loader";

const RegisterPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    referralCode: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showRegisterPassword, setshowRegisterPassword] = useState(false);
  const [showRegisterConfirmPassword, setshowRegisterConfirmPassword] =
    useState(false);
  const [loader, setLoader] = useState(false);

  const handlePassword = () => setshowRegisterPassword(!showRegisterPassword);

  const handleConfirmPassword = () =>
    setshowRegisterConfirmPassword(!showRegisterConfirmPassword);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const validate = () => {
    const newErrors = {};
    if (!data?.firstName.trim()) {
      newErrors.firstName = "First name is required";
    } else if (!data?.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    } else if (!data?.mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(data?.mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid Mobile number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validate1 = () => {
    const newErrors = {};
    if (!data?.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data?.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (!data.password.trim()) {
      newErrors.password = "Password is required";
    } else if (data.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    } else if (!/[A-Z]/.test(data.password)) {
      newErrors.password = "Password must contain at least one capital letter";
    } else if (!/[0-9]/.test(data.password)) {
      newErrors.password = "Password must contain at least one digit";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.password)) {
      newErrors.password =
        "Password must contain at least one special character";
    } else if (/\s/.test(data.password)) {
      newErrors.password = "Password must not contain spaces";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const nextStep = (e) => {
    e.preventDefault();
    if (validate1()) {
      setStep(step + 1);
      localStorage.setItem(
        "registerInfo",
        JSON.stringify({
          email: data?.email,
          password: data?.password,
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const registerInfo = JSON.parse(localStorage.getItem("registerInfo"));
      if (validate()) {
        setLoader(true);
        const obj = {
          email: data?.email ? data?.email : registerInfo?.email,
          password: data?.password ? data?.password : registerInfo?.password,
          mobile: data?.mobileNumber,
          firstName: data?.firstName,
          lastName: data?.lastName,
          referralCode: data?.referralCode,
          countryCode: "+91",
        };
        const res = await signupApi(obj);
        console.log(res, "res");
        if (res?.status == 200) {
          setLoader(false);
          localStorage.setItem("token", res?.data?.data?.accessToken);
          localStorage.setItem("userInfo", JSON.stringify(res?.data?.data));
          toast.success(res?.data?.message);
          navigate("/home");
        } else if (res?.data?.error_description?.statusCode == 400) {
          toast.error(res?.data?.error_description?.message);
          setLoader(false);
        } else {
          toast.error(res?.data?.error_description?.message);
          setLoader(false);
        }
      }
    } catch (err) {
      console.log(err, "err");
      setLoader(false);
      throw err;
    }
  };

  return (
    <>
      {loader && <Loader />}
      {step == "1" && (
        <div className="bg-[#005555] w-full h-screen flex flex-col justify-center py-8 align-center items-center">
          <div className="w-[350px] md:w-[400px] lg:w-[500px] bg-white rounded-lg shadow py-2 md:px-6 md:py-6">
            <>
              <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
                Register Form
              </h2>
              <form onSubmit={nextStep} className="w-full h-full px-8 pt-8">
                <div className="flex flex-col text-md mb-2">
                  <label className="text-black text-sm mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-xs">{errors.email}</p>
                  )}
                </div>

                <div className="flex flex-col text-md mb-2 relative">
                  <label className="text-black text-sm mb-2">Password</label>
                  <input
                    type={showRegisterPassword ? "text" : "password"}
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                    className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Password"
                  />
                  <span
                    className="absolute right-3 top-9 cursor-pointer"
                    onClick={handlePassword}
                  >
                    <FontAwesomeIcon
                      className="text-black "
                      icon={showRegisterPassword ? faEyeSlash : faEye}
                    />
                  </span>
                  {errors.password && (
                    <p className="text-red-500 text-xs">{errors.password}</p>
                  )}
                </div>
                <div className="w-full flex item-center justify-center mt-8">
                  <button
                    type="submit"
                    className="cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
                  >
                    Register
                  </button>
                </div>
                <div className="w-full flex item-center justify-center mt-2">
                  <button
                    type="button"
                    className="cursor-pointer text-[#005555] px-2 py-2 rounded-md w-full"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <u>Already have an account!</u>
                  </button>
                </div>
                <div className="w-full">
                  <p className="w-full text-center text-black text-sm my-4">
                    <u
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/home");
                      }}
                    >
                      GO to Home
                    </u>
                  </p>
                </div>
              </form>
            </>
          </div>
        </div>
      )}
      {step == 2 && (
        <Step1
          data={data}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default RegisterPage;
