import Footer from "../Footer";
import Header from "../Header";

const AuthLayout = ({ pageName, children }) => {
  return (
    <>
      <Header pageName={pageName} />
      <div style={{
        minHeight: "40vh",
      }}>
        {children}
      </div>
      <Footer />
    </>
  );
};
export default AuthLayout;
