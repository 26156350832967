const Footer = () => {
  return (
    <>
      <div className="site-footer">
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-4">
              <div className="widget">
                <h3>Contact</h3>
                <address>Plot No. 310 Hyderabad, Telangana 500033</address>
                <ul className="list-unstyled links">
                  <li>
                    <a href="tel://11234567890">+91 0000000000</a>
                  </li>
                  <li>
                    <a href="mailto:info@sharebric.com">info@sharebric.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="widget">
                <h3>Sources</h3>
                <ul className="list-unstyled float-start links">
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  {/* <li>
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <a href="#">Vision</a>
                  </li>
                  <li>
                    <a href="#">Mission</a>
                  </li> */}
                  <li>
                    <a href="#">Terms</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                </ul>
                {/* <ul className="list-unstyled float-start links">
                  <li>
                    <a href="#">Partners</a>
                  </li>
                  <li>
                    <a href="#">Business</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">FAQ</a>
                  </li>
                  <li>
                    <a href="#">Creative</a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="widget">
                <h3>Links</h3>
                <ul className="list-unstyled links">
                  {/* <li>
                    <a href="#">Our Vision</a>
                  </li> */}
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul>

                <ul className="list-unstyled social">
                  <li>
                    <a href="#">
                      <span className="icon-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon-facebook"></span>
                    </a>
                  </li>
        
        
             
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center">
              <p>
                Copyright &copy;
                <script>document.write(new Date().getFullYear());</script>. All
                Rights Reserved. &mdash; Sharebric
              </p>
              <div>
                {/* Distributed by
                <a href="https://themewagon.com/" target="_blank">
                  themewagon
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
