import React, { useEffect, useState } from "react";
import { getBuyBricsApi } from "../../api";
import ReactPaginate from "react-paginate";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const PurchasedPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Current page index
  const itemsPerPage = 10; // Number of items per page
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchProperties(1);
  }, []);

  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getBuyBricsApi({ page: pageNumber }); // API pagination usually starts from 1
      console.log(response, "response");
      if (response?.status == 200) {
        setLoader(false);
        setProperties(response.data?.data); // Set fetched properties
        setTotalCount(response.data?.count); // Set total number of pages
      } else if (response?.data?.error_description?.statusCode == 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    fetchProperties(selectedPage.selected + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const aggregateProperties = (properties) => {
    return properties.reduce(
      (acc, { amount, rentEarned, appreciation }) => ({
        amount: acc.amount + amount,
        rentEarned: acc.rentEarned + rentEarned,
        appreciation: acc.appreciation + appreciation,
      }),
      { amount: 0, rentEarned: 0, appreciation: 0 }
    );
  };

  const aggregatedData = aggregateProperties(properties);

  const data = [
    { name: "Investment", y: aggregatedData.amount, color: "#E38627" },
    { name: "Rent Earned", y: aggregatedData.rentEarned, color: "#C13C37" },
    { name: "Appreciation", y: aggregatedData.appreciation, color: "#6A2135" },
  ];

  const chartOptions = {
    chart: {
      type: "pie",
      backgroundColor: "#fafafa",
    },
    title: {
      text: "Investment Overview",
    },
    series: [
      {
        name: "Amount",
        data: data,
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.1f} %",
        },
      },
    ],
  };

  return (
    <>
      <p className="text-3xl  text-primary font-bold text-center w-full mb-4">
        Purchased Properties
      </p>
      <div className="section section-properties -mt-12">
        <div className="container">
          <div className="row">
            {!loader && properties && properties.length > 0 ? (
              properties.map((val) => (
                <div
                  key={val.id}
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-4"
                >
                  <div className="property-item mb-30">
                    {val?.property?.images?.length <= 1 ? (
                      <a href={`/property-details?id=${val?.property?.id}`}>
                        <img
                          src={val?.property?.images?.[0]}
                          alt="Image"
                          className="h-[400px] object-cover w-full"
                        />
                      </a>
                    ) : (
                      <Slider {...settings}>
                        {val?.property?.images.map((val1) => {
                          return (
                          <a
                            href={`/property-details?id=${val?.property?.id}`}
                            className="img"
                          >
                            <img
                                src={val1}
                              alt="Image"
                              className="h-[400px] object-cover w-full"
                            />
                          </a>
                          );
                        })}
                      </Slider>
                    )}

                    <div className="property-content">
                      <div className="flex justify-between price mb-2">
                        <span>₹{val?.amount}.00</span>
                        <p className="text-green-600  border-2 text-center border-green-600 rounded-full py-1 px-2 w-32 text-sm">
                          Purchased
                        </p>
                      </div>

                      <div>
                        <span className="city d-block mb-3">
                          {val &&
                          val?.property?.location &&
                          val?.property?.location.length > 25
                            ? val?.property?.location.substring(0, 25) + "..."
                            : val?.property?.location}{" "}
                        </span>
                        <div className="flex flex-col">
                          <span className="font-bold mb-1 text-black">
                            Purchased Brics : {val?.brics}
                          </span>
                          <span className="font-bold mb-2 text-black">
                            Each Bric Price : {val?.property?.eachBricCost}
                          </span>
                        </div>
                        <span className="d-block mb-2 text-black-50">
                          {val?.property?.aboutLocation.length > 75
                            ? val?.property?.aboutLocation.substring(0, 125) +
                              "..."
                            : val?.property?.aboutLocation}
                        </span>

                        <a
                          href={`/property-details?id=${val?.property?.id}`}
                          className="btn btn-primary py-2 px-3"
                        >
                          See details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : loader ? (
              <Loader />
            ) : (
              <div className="col-12 text-center">No properties found.</div>
            )}

            {!loader && properties && properties.length > 0 && (
              <div
                className="property-chart mt-4 relative text-center"
                style={{
                  border: "1px solid #ddd",
                  padding: "40px 30px",
                  display: "inline-block",
                  position: "relative",
                  width: "100%",
                  maxWidth: "500px",
                  margin: "0 auto",
                  backgroundColor: "#fafafa",
                  borderRadius: "10px",
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "300px",
                    margin: "80px auto",
                  }}
                />
              </div>
            )}
          </div>
          {!loader && (
            <ReactPaginate
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              className={"px-2 flex justify-center mt-6 font-bold"}
              pageClassName={
                "ml-2 mr-2  px-2.5 py-1 text-sm rounded-full font-bold"
              }
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active bg-[#005555] text-white px-2.5 py-1 rounded-full"
              previousClassName="hidden"
              nextClassName="hidden"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PurchasedPage;
