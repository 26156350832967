const Step1 = ({ data, errors, handleChange, handleSubmit }) => {
  return (
    <>
      <div className="bg-[#005555] w-full h-screen flex flex-col justify-center py-8 align-center items-center">
        <div className="w-[350px] md:w-[400px] lg:w-[500px] bg-white rounded-lg shadow md:py-6">
          <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
            Welcome!
          </h2>
          <form onSubmit={handleSubmit} className="w-full h-full px-8 py-8">
            <div className="flex flex-row justify-between">
              <div className="w-full flex flex-col text-md mb-2">
                <label className="text-black text-sm mb-2">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={data.firstName}
                  onChange={handleChange}
                  className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                  placeholder="First Name"
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs">{errors.firstName}</p>
                )}
              </div>
              <div className="w-full ml-2 flex flex-col text-md mb-2">
                <label className="text-black text-sm mb-2">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={data.lastName}
                  onChange={handleChange}
                  className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                  placeholder="Last Name"
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col text-md mb-2">
              <label className="text-black text-sm mb-2">Mobile Number</label>
              <input
                type="text"
                name="mobileNumber"
                value={data.mobileNumber}
                onChange={handleChange}
                className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                placeholder="Mobile Number"
              />
              {errors.mobileNumber && (
                <p className="text-red-500 text-xs">{errors.mobileNumber}</p>
              )}
            </div>
            <div className="flex flex-col text-md mb-2">
              <label className="text-black text-sm mb-2">Referral Code</label>
              <input
                type="text"
                name="referralCode"
                value={data.referralCode}
                onChange={handleChange}
                className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                placeholder="Referral Code"
              />
            </div>

            <div className="w-full flex item-center justify-center mt-8">
              <button
                type="submit"
                className="cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Step1;
